import React from "react";

import krasia from "../../assets/image/l5/jpg/krasia-tokped.jpg";
import tirto from "../../assets/image/l5/jpg/tirto-polri.jpg";
import linetoday from "../../assets/image/l5/jpg/linetoday-kp.jpeg";
import cnn from "../../assets/image/l5/jpg/cnn-kp.jpeg";
import techinasia from "../../assets/image/l5/jpg/techinasia-tokped.jpg";
import krasia2 from "../../assets/image/l5/jpg/krasia-attacks.jpg";
import techinasia2 from "../../assets/image/l5/jpg/techinasia-gojek.jpg";
import krasia3 from "../../assets/image/l5/jpg/krasia-indihome.jpg";
import cnn2 from "../../assets/image/l5/jpg/cnn-periksadata.jpeg";

const Press = () => (
  <>
    {/* <!-- CaseStudies section --> */}
    <div class="news-section mt-md-6 pt-24 pt-lg-29">
    <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-6 col-lg-7 col-md-9">
        <div class="section-title text-center mb-11 mb-lg-18">
          <h2 class="title gr-text-2 mb-9 mb-lg-12">Live TV</h2>
          <p class="gr-text-8 mb-0">Kumpulan video dari serangkaian acara yang diadakan oleh Ethical Hacker Indonesia</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FEthicalhack.ID%2Fvideos%2F1670449133105229%2F&show_text=0&width=560" class="w-100 rounded-top-10" height="215" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Abdullah</p>
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Bug Bounty Recon : Asset Discovery</h3>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FEthicalhack.ID%2Fvideos%2F671096553738564%2F&show_text=0&width=560" class="w-100 rounded-top-10" height="215" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Bug Hunters & Program Manager</p>
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Keluh Kesah Bug Bounty Hunter</h3>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FEthicalhack.ID%2Fvideos%2F859472564549865%2F&show_text=0&width=560" class="w-100 rounded-top-10" height="215" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Rexha</p>
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Web Application Penetration Testing</h3>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FEthicalhack.ID%2Fvideos%2F600708420788379%2F&show_text=0&width=560" class="w-100 rounded-top-10" height="215" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Willy Robertus Leonardo</p>
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Mobile App Static Analysis Tools & Sniffing</h3>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FEthicalhack.ID%2Fvideos%2F323576085289604%2F&show_text=0&width=560" class="w-100 rounded-top-10" height="215" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Anjaswari Curanjaya</p>
              <h3 class="title text-blackish-blue gr-text-7 mb-0">From Webshell to Excel </h3>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FEthicalhack.ID%2Fvideos%2F523865474962347%2F&show_text=0&width=560" class="w-100 rounded-top-10" height="215" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Zen Rooney</p>
              <h3 class="title text-blackish-blue gr-text-7 mb-0">How to Become a Hacker? It’s fun!</h3>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FEthicalhack.ID%2Fvideos%2F243360883476258%2F&show_text=0&width=560" class="w-100 rounded-top-10" height="215" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Feri Harjulianto</p>
              <h3 class="title text-blackish-blue gr-text-7 mb-0">Best Practice GIT Hacks</h3>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FEthicalhack.ID%2Fvideos%2F555030388778765%2F&show_text=0&width=560" class="w-100 rounded-top-10" height="215" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Satria Ady Pradana</p>
              <h3 class="title text-blackish-blue gr-text-7 mb-0">IoT Security FUN-damental</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </>
);

export default Press;
