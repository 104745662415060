import React from "react";
import PageWrapper from "../components/PageWrapper";
import Videos from "../sections/landing/Videos";
import { Helmet } from "react-helmet";

const PressPage = () => {
  return (
    <>
    <Helmet>
        <title>Live TV - Ethical Hacker Indonesia</title>  
        <meta name="description" content="Kumpulan video dari serangkaian acara yang diadakan oleh Ethical Hacker Indonesia" />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/press/" />
        <meta property="og:title" content="Live TV" />
        <meta property="og:description" content="Kumpulan video dari serangkaian acara yang diadakan oleh Ethical Hacker Indonesia" />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="Live TV"  />
        <meta name="twitter:description" content="Kumpulan video dari serangkaian acara yang diadakan oleh Ethical Hacker Indonesia" />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>
      <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <Videos />
      </PageWrapper>
    </>
  );
};
export default PressPage;
